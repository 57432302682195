'use client'

import { usePathname } from 'next/navigation'

export const DraftModeMenu = ({ isEnabled }: { isEnabled: boolean }) => {
  const pathname = usePathname()

  // Prefer classic a links over next/link as it is more reliable at hitting
  // api endpoint and being redirect. next/link seems to glitch out a lot on the
  // client side when doing this.
  return isEnabled ? (
    <a
      className="tw-rounded-br-xl tw-p-4"
      href={`${process.env.NEXT_PUBLIC_BASE_PATH}/api/draft/disable/?path=/${pathname}`}
    >
      ✅ Disable Preview
    </a>
  ) : (
    <a
      className="tw-rounded-br-xl tw-p-4"
      href={`${process.env.NEXT_PUBLIC_BASE_PATH}/api/draft/enable/?path=/${pathname}`}
    >
      ☑️ Enable Preview
    </a>
  )
}
